import axios, { AxiosError } from 'axios';
import { BackendLogger } from './BackendLogger';

// eslint-disable-next-line
function isAxiosError(error: any) {
    return typeof error.toJSON !== 'undefined';
}

export function handleAxiosError(error: Error | AxiosError, postToBackendErrorLogger: boolean, apiUrl: string) {
    let errorString = '';
    const isAxios = isAxiosError(error);
    const axiosError = isAxios ? error as AxiosError : null;
    if (isAxios && axiosError.response) {
        errorString += 'HTTP StatusCode: ';
        errorString += axiosError.response.status;
        errorString += ', response object: ';
        errorString += JSON.stringify(axiosError.response.data);
        errorString += ', headers: ';
        errorString += JSON.stringify(axiosError.response.headers);
    } 
    if (isAxios && axiosError.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        errorString += axiosError.request;
    } 
    if (axios.isCancel(error)) {
        errorString += 'Request canceled';
        errorString += error.message;
    } 
    const errorMsg = isAxiosError
        ? axiosError.message
        : `${error.name}: ${error.message}`;
    const errorStack = isAxiosError
        ? JSON.stringify(axiosError.toJSON())
        : error.stack;

    errorString += `fetch() failed with "${errorMsg}". Full error:\n${errorStack}`;
    if (postToBackendErrorLogger) {
        new BackendLogger(apiUrl).postError(errorString);
    } else {
        // eslint-disable-next-line
        console.warn(errorString);
    }
}
