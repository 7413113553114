import axios, { AxiosError } from 'axios';
import { handleAxiosError } from './axios-error-handling';

export class BackendLogger {
    private apiUrl: string;

    constructor(apiUrl: string) {
        this.apiUrl = apiUrl;
    }

    public postError(error: string): Promise<void> {
        const requestUrl = `${this.apiUrl}log-error`;
        const bodyFormData = new FormData();
        bodyFormData.append('error', error);
        return axios.post<void>(requestUrl, bodyFormData, {
            headers: { 'Content-Type': 'multipart/form-data' },
        }).then(() => {
            return;
        }).catch((axioError: Error | AxiosError) => {
            handleAxiosError(axioError, false, this.apiUrl); //false to avoid loop
        });
    }
}
