import React from 'react';
import styles from '../bookmark-modal/bookmarkModal.scss';

/* eslint-disable */
const Cross = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="44.99997" height="45" viewBox="0 0 44.99997 45">
        <path className={styles.svg_border} d="M22.5,1A21.5,21.5,0,1,1,1,22.5,21.52428,21.52428,0,0,1,22.5,1m0-1A22.5,22.5,0,1,0,45,22.5,22.49993,22.49993,0,0,0,22.5,0Z" />
        <path className={styles.svg_cross} d="M23.77313,22.49976l6.86328-6.86329A.90011.90011,0,0,0,29.364,14.363l-6.86377,6.86377L15.63641,14.363A.90011.90011,0,0,0,14.364,15.63647l6.86328,6.86329L14.364,29.363a.90011.90011,0,1,0,1.27246,1.27343l6.86377-6.86376L29.364,30.63647A.90011.90011,0,0,0,30.63641,29.363Z" />
    </svg>
);

export default Cross;
