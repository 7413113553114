import axios, { AxiosResponse, AxiosError } from 'axios';
import { handleAxiosError } from './axios-error-handling';
import { ArticleData } from '../types';

export class AddResponse {
    added: boolean;
    logged: boolean;
}

export class BackendAjaxAdapter {

    public addBookmark(articleData : ArticleData): Promise<AddResponse> {
        const apiUrl = window.location.protocol + '//' + window.location.hostname+ '/api/bookmark/';
        const requestUrl = `${apiUrl}bookmark-add?salesArticleVariantKey=${articleData.savKey}`;
        return axios.post<string>(requestUrl)
                .then((response: AxiosResponse) =>
                    response.status === 200 ? response.data : undefined)
                // eslint-disable-next-line
                .catch<Error | AxiosError<AxiosResponse>>((error: Error | AxiosError<AddResponse>) => {
                    const isAxios = this.isAxiosError(error);
                    const axiosError = isAxios ? error as AxiosError<AddResponse> : null;
                    const logged = isAxios && axiosError.response.data.logged;
                    handleAxiosError(error, !logged, apiUrl);
                    return error;
                });
    }
    // eslint-disable-next-line
    private isAxiosError(error: any) {
        return typeof error.toJSON !== 'undefined';
    }
}
