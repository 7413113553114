import React from 'react';
import {
    ArticleData, ArticleDataInjectionRequest, BookmarkIntent,
    IBookmarkArticleDataResponse, IBookmarkButtonProps, IBookmarkModalProps
} from '../types';
import styles from './bookmarkModal.scss';
import BookmarkIcon from '../icons/bookmark_icon';
import { AddResponse, BackendAjaxAdapter } from '../bookmark-button/BackendAjaxAdapter';
import Cross from '../icons/cross';
import { EsAppHelper } from '../../helpers/esAppHelper';
import { ScrollLock } from '../../helpers/scrollLock';

function removeParameters() {
    const url = new URL(window.location.href);
    url.searchParams.delete('addBookmark');
    url.searchParams.delete('bm');
    const urlWithoutParam = url.toString();
    history.replaceState({}, '', urlWithoutParam);
}

const backend: BackendAjaxAdapter = new BackendAjaxAdapter();
const esAppHelper: EsAppHelper = new EsAppHelper();
const scrollLock: ScrollLock = ScrollLock.instance;

// this component should exist exactly once on every page
function BookmarkModalSingleton(props: IBookmarkModalProps) {
    const [openState, setOpenState] = React.useState(props.isOpen);
    const [articleDataState, setArticleDataState] = React.useState<ArticleData>(props.articleDataAfterLogin);

    React.useEffect(() => {
        if (typeof window !== 'undefined' && window.shell) {
            // react to special URL parameter that triggers bookmark after login (logged out user clicked bookmark)
            const isAfterLoginCase = !!props.articleDataAfterLogin;
            if (isAfterLoginCase) {
                // we already added the bookmark server-side 
                OpenBookmarkModal(props.articleDataAfterLogin);

                //if modal is already opened serverside, we still need to trim the URL
                removeParameters();
            }

            window.shell.subscribeTo('ESCID.ESPP.Bookmark.ButtonWasClicked',
                (intent: BookmarkIntent) => {
                    OnBookmarkIntent(intent.origin);
                },
                'ESCID.ESPP.Bookmark');
        }
    }, []);

    function OnBookmarkIntent(intent: IBookmarkButtonProps) {

        //reacts to button click or after-login-bookmark -> gets data, calls backend, opens modal
        const articleDataRequest: ArticleDataInjectionRequest = {
            requester: intent?.containerId
        };
        if (props.isLoggedIn) {
            //listen for response
            window.shell.subscribeTo('ESCID.ESPP.Bookmark.ArticleDataInjection',
                (articleDataResponse: IBookmarkArticleDataResponse) => {

                    if (articleDataResponse.requester !== intent.containerId) return;
                    const articleData: ArticleData = articleDataResponse;

                    backend.addBookmark(articleData).then((addResponse: AddResponse) => {
                        if (addResponse.added)
                            OpenBookmarkModal(articleData);
                        //else
                        //    console.error(addResponse.failureReason);
                    });
                }, 'ESCID.ESPP.Bookmark.ArticleDataInjection');
            //make request
            window.shell.publishTo('ESCID.ESPP.Bookmark.RequestArticleDataInjection', articleDataRequest);
        } else {

            window.shell.once('ESCRM.AccountMenu.AccountMenuSwitchedToLoginRequestedMode', () => {
                //this opens the flyout
                window.shell.publishTo('ESMKT.HeadR.ToggleFlyout', 'LoginFlyout');
                //close also legacy order modal
                window.shell.publishTo('mpc.close-modal', null);
                //close order modal
                window.shell.publishTo('ESPP.OrderModal.Close', null);
            });

            window.shell.subscribeTo('ESCID.ESPP.Bookmark.ArticleDataInjection',
                (articleData: ArticleData) => {
                    const currentPage: string = window.location.pathname + window.location.search;
                    const objJsonStr = JSON.stringify(articleData);
                    const objJsonB64 = b64EncodeUnicode(objJsonStr);
                    const urlEncodedBase64 = encodeURIComponent(objJsonB64);
                    const urlWithParam = currentPage + (currentPage.indexOf('?') >= 0 ? '&' : '?')
                        + 'addBookmark=true'
                        + '&bm=' + urlEncodedBase64;

                    if (props.isEsApp) {
                        //esApp does not use AMS, but instead redirects to Login Page 
                        // we created our own login page that contains a special message that "bookmark requires login"

                        const urlWithParamEncoded = encodeURIComponent(urlWithParam)

                        let param = '?';
                        if(props.useNewAccountService) {
                            param += 'loginRequired=true&redirectUrl='
                        } else {
                            param += 'loginSuccessForwardPath='
                        }
                        param += urlWithParamEncoded;
                        let loginForEsAppWithRedirect = '';
                        if(props.useNewAccountService) {
                            loginForEsAppWithRedirect += props.localization.esAppLoginPageUrl 
                        } else {
                            loginForEsAppWithRedirect += props.localization.esAppOldLoginPageUrl 
                        }
                        loginForEsAppWithRedirect += param;
                        window.location.href = loginForEsAppWithRedirect;
                        return;
                    }

                    const payload: IBookmarkRequestsLogin = {
                        redirectUrl: urlWithParam,
                        message: props.localization.loginHint
                    };

                    //we created our own AMS login flyout design
                    //that contains a special message that "bookmark requires login"
                    //this switches the flyout design
                    window.shell.publishTo('ESCRM.AccountMenu.ServiceRequestsLogin', payload);
                    window.shell.publishTo('legacy.modals.close', null);
                }, 'ESCID.ESPP.Bookmark.ArticleDataInjection');

            window.shell.publishTo('ESCID.ESPP.Bookmark.RequestArticleDataInjection', articleDataRequest);
        }
    }

    function OpenBookmarkModal(articleDataFromEvent: ArticleData) {
        if (articleDataFromEvent) {
            scrollLock.lock();
            setArticleDataState(articleDataFromEvent);
            setOpenState(true);
            closeOrderModal();
            esAppHelper.navigationWheel.hide();
        }
    }

    function b64EncodeUnicode(str) {
        return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1) {
            return String.fromCharCode(parseInt(p1, 16))
        }))
    }

    function closeBookmarkModal() {
        scrollLock.unlock();
        esAppHelper.navigationWheel.show();
        setOpenState(false);
        window.shell.publishTo('ESCID.ESPP.Bookmark.RequestQuickOrderModalClose', null);
    }

    const bookmarkModalRef = React.useRef();
    const { localization } = props;

    function goToShortlistPage(event: React.MouseEvent) {
        event.preventDefault();
        if (props.isEsApp) {
            esAppHelper.navigationWheel.show();
            // on esApp we have to show the wheel and trigger the navigation
            setTimeout(function () {
                window.location.href = localization.shortlistPageUrl;
            }, 300);
        } else {
            /*
             * For now, it is assumed that the used is logged in 
             * (otherwise it would not be possible to see Bookmark modal)
             * after we do the real check we will actually add an item to customer's list, for now we only have redirect
            */
            window.location.href = localization.shortlistPageUrl;
        }
    }

    function closeOrderModal() {
        if (window.shell) {
            window.shell.publishTo('ESPP.OrderModal.Close', null);
        }
    }

    if (!openState) {
        return <></>;
    }

    const assetReleaseMode = props.isRelease ? 'images' : 'images_preview';

    const imageUrl = localization.cdnBaseUrl + 'assets/bookmark/'
        + assetReleaseMode + '/BookmarkModalThumbnail/' + articleDataState.imageUrl;

    function getAltTagValue(): string {
        const categoryName = articleDataState.categoryName ? 
            ', ' + articleDataState.categoryName: '';
        
        const colorName = articleDataState.colorName ? 
            ', ' + articleDataState.colorName : '';

        return props.localization.mainView + categoryName + ', ' + articleDataState.productName + colorName;
    }

    return (
        <div
            className={styles.bookmark_modal}
            onClick={closeBookmarkModal}
            data-testid="bookmark_modal_root">
            <div
                ref={bookmarkModalRef}
                className={styles.bookmark_modal_container}
                data-testid="bookmark-container"
            >
                <div className={styles.bookmark_modal_content}>
                    <h2 className={styles.bookmark_modal_title}>
                        <span className={styles.bookmark_modal_title_text}>{localization.modalTitle}</span>
                        <div
                            className={styles.close_button}
                            data-testid="bookmark_close_button">
                            <Cross />
                        </div>

                    </h2>
                    <div className={styles.bookmark_modal_middle}>
                        <div className={styles.image_container}>
                            <img
                                data-testid='bookmark_image'
                                alt={getAltTagValue()}
                                className={styles.bookmark_image}
                                src={imageUrl} />
                        </div>
                        <div className={styles.bookmark_modal_text_box}>
                            <p className={styles.bookmark_modal_text_bold}>
                                {localization.hasBeenAdded}
                            </p>
                            <p className={styles.bookmark_modal_text_bold}>
                                {articleDataState.productName}
                            </p>
                            {articleDataState.colorName && <p className={styles.bookmark_modal_text_regular}>
                                <span data-label="color" className={styles.color_capital_letter}>
                                    {localization.color}{localization.colon}&nbsp;</span>
                                {articleDataState.colorName}
                            </p>}
                            {articleDataState.sizeName && <p className={styles.bookmark_modal_text_regular}>
                                <span data-label="size">{localization.size}{localization.colon}&nbsp;</span>
                                {articleDataState.sizeName}
                            </p>}
                            {articleDataState.modelName && <p className={styles.bookmark_modal_text_regular}>
                                <span data-label="model">{localization.model}{localization.colon}&nbsp;</span>
                                {articleDataState.modelName}
                            </p>}
                        </div>
                    </div>
                    <div className={styles.bookmark_modal_footer}>
                        <button data-testid="to_bookmarked_items_button" onClick={e => goToShortlistPage(e)}
                            className={styles.to_bookmarked_items_button}>
                            <BookmarkIcon />
                            <span>{localization.goToBookmarkPage}</span>
                        </button>
                        <button data-testid="continue_shopping_button"
                            className={styles.continue_shopping_button}>
                            {localization.continueShopping}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

//interface towards AMS
interface IBookmarkRequestsLogin {
    redirectUrl: string,
    message: string,
}

export default BookmarkModalSingleton;
