import * as React from 'react';
import * as ReactDOM from 'react-dom';
import BookmarkButton from './components/bookmark-button/BookmarkButton';
import BookmarkModalSingleton from './components/bookmark-modal/bookmarkModalSingleton';
import {IBookmarkModalProps, IBookmarkButtonProps} from './components/types.d';
import { isTablet } from 'react-device-detect';

function initBookmarkButtonAfterAjaxModalLoaded(modal: Element){
    const props = JSON.parse(JSON.stringify(window.__PRELOADED_BOOKMARK_AJAX_BUTTON_STATE__)) as IBookmarkButtonProps;
    const modalSelector = props.isRedesignOrderModal ? '.om-order_modal' : '.modal.in';
    if(!modal){
        modal = document.body.querySelectorAll(modalSelector)[0];

        if (!modal)
            return;
    }
    const bookmarkButtonInModalCandidates = modal.querySelectorAll('.hidden[data-service-call="bookmark-add-button"]')
    if(!bookmarkButtonInModalCandidates || bookmarkButtonInModalCandidates.length == 0)
        return;
    const bookmarkButtonInModal = bookmarkButtonInModalCandidates[0];
    
    //replace placeholder class by actual button class
    bookmarkButtonInModal.className = props.isRedesignOrderModal && (!props.isMobile && !isTablet) ? 'bookmark_icon' :
        'pdp-legacy_ordermodal_bookmark_add_button_outer';

    bookmarkButtonInModal.removeAttribute('data-service-call');
    ReactDOM.render(
        <BookmarkButton {...props}/>,
        bookmarkButtonInModal,
        () => {
            if (window.shell) {
                window.shell.publishTo('ESPP.Bookmark.BookmarkButton.DidRendered', null);
            }
        }
    );
}

function hydrateModal() {
    if (window.shell) {
        window.shell.subscribeTo('legacy.modals.openbyajax.ajaxfinished',
            initBookmarkButtonAfterAjaxModalLoaded, 'ESPP.Bookmark.QuickOrderModalIntegration')
        window.shell.subscribeTo('legacy.article-templates.updated.sizes',
            initBookmarkButtonAfterAjaxModalLoaded, 'ESPP.Bookmark.QuickOrderModalIntegration')
        window.shell.subscribeTo('legacy.article-templates.updated.article',
            initBookmarkButtonAfterAjaxModalLoaded, 'ESPP.Bookmark.QuickOrderModalIntegration')
    }

    const hydratedProps = JSON.parse(JSON.stringify(window.__PRELOADED_BOOKMARK_MODAL_STATE__)) as IBookmarkModalProps;
    delete window.__PRELOADED_BOOKMARK_MODAL_STATE__;
    ReactDOM.hydrate(
        <BookmarkModalSingleton {...hydratedProps} />,
        document.querySelector('#bookmark-quick-order-modal-integration')
    );
}

hydrateModal();
